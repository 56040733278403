import { get } from 'lodash'
import { generateUUID } from "../../../utils";

export const QUESTION_TYPES = {
    DATE: 'date',
    BOOL: 'bool',
    STRING: 'string',
    LONGTEXT: 'longtext',
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
    SELECT: 'select',
    MONEY: 'money',
    DECIMAL: 'decimal',
    NUMBER: 'number',
    PHOTO: 'photo'
}


export class ComponentModel {

    constructor(body) {

        // primary props
        this.id = get(body,'id')
        this.surveyId = get(body,'surveyId')
        this.name = get(body,'name')
        this.uuid = get(body,'uuid',generateUUID())
        this.hideLabel = get(body,'hideLabel')
        this.hide = get(body,'hide',false)
        this.activeDateFrom = get(body,'activeDateFrom')
        this.activeDateTo = get(body,'activeDateTo')
        this.options = get(body,'options',{})
        this.repeats = get(body,'repeats')
        this.repeatsUnit = get(body,'repeatsUnit')
        this.repeatsValue = get(body,'repeatsValue')
        this.conditionals = get(body,'conditionals',[])

        //join props
        this.survey_questions = get(body,'survey_questions',[])
        this.products = get(body,'products',[])
        this.image = get(body,'image')

        //extended props
    }
}

export class QuestionModel {

    constructor(body) {

        // primary props
        this.id = get(body,'id')
        this.name = get(body,'name')
        this.uuid = get(body,'uuid',generateUUID())
        this.hideLabel = get(body,'hideLabel')
        this.hide = get(body,'hide',false)
        this.activeDateFrom = get(body,'activeDateFrom')
        this.activeDateTo = get(body,'activeDateTo')
        this.options = get(body,'options', {})
        this.repeats = get(body,'repeats')
        this.repeatsUnit = get(body,'repeatsUnit')
        this.repeatsValue = get(body,'repeatsValue')
        this.conditionals = get(body,'conditionals',[])
        this.presetValue = get(body,'presetValue')
        this.isMandatory = get(body,'isMandatory')
        this.limitRecords = get(body,'limitRecords')

        //join props
        this.image = get(body,'image')
        this.survey_question_type = get(body, 'survey_question_type')

    }
}