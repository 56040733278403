<template>


    <div class="d-flex flex-column" v-if="component">

      <div class="d-flex mb-1">
        <label class="mr-1">id:</label>
        <b-badge v-if="!component.id" variant="danger" class="mt-1 mx-1" style="height: 16px;">N/A</b-badge>
        {{component.id}}
      </div>

      <div class="d-flex mb-1">
        <label class="mr-1">uuid:</label>{{component.uuid}}
      </div>

      <div class="d-flex mb-1">
        <label class="mr-1">nombre:</label>
        <input class="input-small" type="text" v-model="component.name"/>
      </div>

      <div class="d-flex">
        <label class="mr-1 mb-2">ocultar nombre:</label>

        <input
                class="mr-3"
                type="checkbox"
                v-model="component.hideLabel"
        />

      </div>

      <div class="d-flex mb-1">
        <label class="mr-1">desactivar?: </label>
        <input
                class="mr-3"
                style="margin-top: 3px"
                type="checkbox"
                v-model="component.hide"
        />
      </div>


      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column datepicker">
          <label class="m-0">activo desde:</label>
          <b-form-datepicker
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm" locale="es"
                  v-model="component.activeDateFrom"
                  class="mb-2">

          </b-form-datepicker>
        </div>

        <div class="d-flex flex-column datepicker">
          <label class="m-0">hasta:</label>
          <b-form-datepicker
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm" locale="es"
                  v-model="component.activeDateTo"
                  class="mb-2">

          </b-form-datepicker>
        </div>

      </div>


      <div class="d-flex mb-2">
        <label class="mr-1">se repite?: </label>
        <input
                class="mr-3"
                style="margin-top: 3px"
                type="checkbox"
                v-model="component.repeats"
        />
        <template v-if="component.repeats">
          <label class="mr-1">cada: </label>
          <input
                  min="0"
                  class="input-small mr-1"
                  style="margin-top: 3px; width: 50px"
                  type="number"
                  v-model="component.repeatsUnit"
          />

          <select class="cd-select" style="height: 20px; width: 80px ;margin-top: 3px" v-model="component.repeatsValue">
            <option value="days">dias</option>
            <option value="weeks">semanas</option>
            <option value="months">meses</option>
          </select>


        </template>

      </div>



      <fieldset class="m-0 p-1">
        <legend><b-badge class="legend-title">Productos</b-badge></legend>
        <div class="d-flex flex-column mt-1">

          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <label class="m-0">marca:</label>
              <div class="d-flex">
                <select class="cd-select" v-model="selectedBrand">
                  <option :key="`cd_select_brand_${key}`" v-for="(brand, key) in xBrands" v-bind:value="brand.id">{{ brand.name }}</option>
                </select>
                <b-button @click="selectedBrand=null" size="sm" class="btn-small mr-1 no-radius" variant="outline-secondary">
                  <b-icon icon="backspace" ></b-icon>
                </b-button>

              </div>
            </div>
            <div class="d-flex flex-column">
              <label class="m-0">familia:</label>
              <div class="d-flex">
                <select class="cd-select" v-model="selectedFamily">
                  <option :key="`cd_select_brand_${key}`" v-for="(family, key) in xFamilies" v-bind:value="family.id">{{ family.name }}</option>
                </select>
                <b-button @click="selectedFamily=null" size="sm" class="btn-small mr-1 no-radius" variant="outline-secondary">
                  <b-icon icon="backspace"></b-icon>
                </b-button>
              </div>

            </div>

          </div>

          <div class="d-flex flex-column">
            <label class="m-0">producto:</label>

            <div class="d-flex">
              <multiselect :showLabels="false"
                           v-model="selectedProduct"
                           :options="xProducts"
                           :custom-label="formatMultiselect"
                           placeholder="selecciona producto"
                           label="name"
                           track-by="name">
              </multiselect>

            </div>

            <div class="d-flex flex-column">

              <div class="border font-xs" style="color: blueviolet" :key="`cd_product_item_${key}`" v-for="(prod,key) in component.products">
                <div class="d-flex justify-content-between">
                  {{ellipsis(prod.name,70)}}
                  <input type="button" class="button-xs " value="X" @click="removeProduct(prod)">

                </div>
              </div>
            </div>

          </div>
        </div>

      </fieldset>


    </div>



</template>

<script>

  import { mapGetters } from "vuex";
  import { uniqBy } from 'lodash'
  import { ellipsis } from '@/utils'
  import Multiselect from 'vue-multiselect'


  export default {
    props: ["component","otherComponents"],
    components: {
      Multiselect
    },
    data() {
      return {
        selectedProduct: null,
        selectedBrand: null,
        selectedFamily: null,
      }
    },

    watch: {
      selectedProduct(prod) {
        if(prod && !this.component.products.some(el=>el.id === prod.id)){
          this.component.products.push(prod)
        }
      },

      selectedBrand(val, old) {
        //resets
        this.selectedFamily = null
        this.selectedProduct = null
      }

    },

    computed: {
      ...mapGetters('products',["products"]),

      xBrands() {
        return uniqBy((this.products || []).map(el=>el.brand),'id')
      },
      xFamilies() {
        const families = uniqBy((this.products || []).map(el=>el.family),'id')
        return families.filter(el => this.selectedBrand ? el.brandId === this.selectedBrand : true)
      },
      xProducts() {
        return this.products
                .filter(el => this.selectedBrand ? el.brandId === this.selectedBrand : true)
                .filter(el => this.selectedFamily ? el.familyId === this.selectedFamily : true)
                .filter(el => !this.component.products.some(p => p.id === el.id))

      }

    },
    
    methods: {
      ellipsis,

      removeProduct(prod) {
        this.component.products = this.component.products.filter(p=>p.id !== prod.id)
      },

      formatMultiselect(el) {
        return this.ellipsis(el.name,55)
      },

      nameWithLang ({ name, language }) {
        return `${name} — [${language}]`
      }
    },

    created() {
      this.component.products = this.component.products || []
    }

  };
</script>

<style>


  .datepicker {
    width: 172px;
  }

  .cd-select {
    width: 145px;
  }

</style>
