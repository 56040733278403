<template>
  <div class="d-flex flex-column">
    <template v-if="survey">
      <div class="border mt-1 ml-2" style="width: 500px; padding-bottom: 3px">
        <h5>
          <b-badge variant="primary"> {{ survey.brand.name }} </b-badge>
          {{ survey.name }}
        </h5>

        <router-link :to="`/config/surveys`">
          <b-button
            class="mr-1 ml-1 btn-small"
            variant="outline-secondary"
            size="sm"
          >
            <b-icon icon="arrow-left"></b-icon> volver
          </b-button>
        </router-link>

        <b-button
          size="sm"
          class="btn-small"
          @click="save"
          variant="outline-success"
        >
          <b-icon icon="cloud-upload"></b-icon> guardar
        </b-button>
      </div>

      <div class="d-flex" style="overflow-x: scroll">
        <fieldset class="input-box">
          <legend><b-badge class="legend-title">Inputs</b-badge></legend>

          <draggable
            :group="{
              name: 'questions',
              move: false,
              pull: 'clone',
              put: false,
            }"
            :clone="onQuestionDrag"
            :list="questionTypes"
            :sort="false"
            class="d-flex flex-wrap justify-content-start"
          >
            <question-box
              :key="`survey_question_type_${key}`"
              v-for="(type, key) in questionTypes"
              :questionType="type"
            ></question-box>
          </draggable>
        </fieldset>

        <fieldset class="survey-box">
          <legend><b-badge class="legend-title">Encuesta</b-badge></legend>

          <draggable
            :list="survey.survey_components"
            group="components"
            @start="drag = true"
            @end="drag = false"
          >
            <div
              :key="`survey_component_${key}`"
              v-for="(component, key) in survey.survey_components"
              @click="editedComponent = component"
              class="mb-2"
            >
              <div class="d-flex justify-content-between">
                <label
                  v-if="component.name"
                  class="my-0 component-title-item"
                  >{{ component.name }}</label
                >
                <label
                  v-else
                  class="my-0 component-title-item"
                  style="color: #b4b3b3"
                  >{{ component.uuid }}</label
                >

                <div class="d-flex">
                  <input
                    type="button"
                    class="small-button button-danger"
                    value="-eliminar"
                    @click="removeComponent(component)"
                  />

                  <!--                      <input type="button" class="small-button" style="background: #cccccc" value="+clonar" @click="cloneComponent(component)">-->
                </div>
              </div>

              <draggable
                :list="component.survey_questions"
                group="questions"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
              >
                <transition-group
                  type="transition"
                  class="d-flex flex-wrap component-edit-box"
                  :class="{
                    'component-selected':
                      editedComponent &&
                      editedComponent.uuid === component.uuid,
                  }"
                >
                  <div
                    v-for="(item, key) in component.survey_questions"
                    :key="`survey_component_question_${key}`"
                    class="d-flex flex-column component-question-box"
                    :class="resolveClassQuestion(item)"
                    @click="editedQuestion = item"
                  >
                    <label class="m-0" v-if="item.name">{{
                      ellipsis(item.name, 15)
                    }}</label>

                    <label class="m-0" style="color: #b4b3b3;" v-else
                      >sin nombre</label
                    >

                    <survey-label
                      :type="item.survey_question_type.type"
                      :icon="item.survey_question_type.icon"
                      :label="item.survey_question_type.type"
                    ></survey-label>
                  </div>
                </transition-group>
              </draggable>

              <b-button
                size="sm"
                class="btn-small mr-2"
                variant="outline-primary"
                @click="addComponent"
              >
                <b-icon icon="plus-circle"></b-icon>
                nuevo
              </b-button>
            </div>
          </draggable>

          <b-alert
            v-if="!survey.survey_components.length"
            class="m-0 p-2 my-alert"
            show
            ><i> Pulsa añadir un componente para empezar</i></b-alert
          >
        </fieldset>

        <div class="d-flex flex-column details">
          <fieldset class="detail-box">
            <legend>
              <b-badge class="legend-title">Detalle Componente</b-badge>
            </legend>

            <component-detail
              :key="'comp_detail_' + editedComponent.uuid"
              v-if="editedComponent"
              :component="editedComponent"
              :otherComponents="survey.survey_components"
            />

            <b-alert v-else variant="warning" class="m-0 p-2 my-alert" show
              ><i> Selecciona un elemento</i></b-alert
            >
          </fieldset>

          <fieldset class="detail-box">
            <legend>
              <b-badge class="legend-title">Detalle Pregunta</b-badge>
            </legend>

            <question-detail
              :key="'quest_detail_' + editedQuestion.uuid"
              v-if="editedQuestion"
              :components="survey.survey_components"
              @remove="removeQuestion(editedQuestion)"
              :question="editedQuestion"
            />

            <b-alert v-else variant="warning" class="m-0 p-2 my-alert" show
              ><i> Selecciona una pregunta</i></b-alert
            >
          </fieldset>
        </div>
      </div>
    </template>

    <b-alert :show="!this.id" variant="warning" style="margin-top: 15px">
      Ninguna encuesta seleccionada o error al obtener datos del servidor:
      vuelve a la lista y selecciona una encuesta
      <router-link :to="`/config/surveys`">
        <b-button
          class="mr-1 ml-1 btn-small"
          variant="outline-secondary"
          size="sm"
        >
          <b-icon icon="arrow-left"></b-icon> volver
        </b-button>
      </router-link>
    </b-alert>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import questionBox from "./questionBox";
import questionDetail from "./questionDetail";
import componentDetail from "./componentDetail";

import { QuestionModel, ComponentModel } from "../models";
import surveyLabel from "@/components/survey/surveyLabel";
import { ellipsis, logWarning, logInfo, logError } from "@/utils";

import ApiService from "@/services/apiService";
import _ from "lodash";
import { orderByUUID } from "../../../../common";

export default {
  name: "SurveyEditor",
  components: {
    draggable,
    questionBox,
    questionDetail,
    componentDetail,
    surveyLabel,
  },
  data() {
    return {
      id: null,

      survey: null,

      questionTypes: [],

      editedQuestion: null,

      editedComponent: null,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "questions",
        disabled: false,
        //ghostClass: "ghost"
      };
    },
  },
  methods: {
    async save() {
      for (const comp of this.survey.survey_components) {
        if (comp.survey_questions.length === 0) {
          logWarning("Hay componentes vacios, revisa antes de guardar");
          return;
        }
        if (!this.isComponentCompleted(comp)) {
          logWarning("Hay componentes incompletos, revisa antes de guardar");
          return;
        }

        for (const q of comp.survey_questions) {
          if (!this.isQuestionCompleted(q)) {
            logWarning("Hay preguntas incompletas. Revisa antes de guardar");
            return;
          }
        }
      }

      try {
        await ApiService.put(`/surveys/${this.id}/components`, {
          components: this.survey.survey_components,
        });
        logInfo("Encuesta guardada");
      } catch (e) {
        logError("Error al guardar encuesta: " + e.toString());
      }
    },

    addComponent() {
      const comp = new ComponentModel({ surveyId: this.id });
      this.survey.survey_components.push(comp);
    },

    ellipsis,

    resolveClassQuestion(item) {
      if (this.editedQuestion && this.editedQuestion.uuid === item.uuid) {
        return "component-question-selected";
      }
      return this.isQuestionCompleted(item)
        ? "component-question-ok"
        : "component-question-ko";
    },

    isQuestionCompleted(item) {
      return !!item.name;
    },

    isComponentCompleted(item) {
      return !!item.name;
    },

    removeComponent(component) {
      if (window.confirm("Estas seguro que quieres eliminar el componente?")) {
        this.survey.survey_components = this.survey.survey_components.filter(
          (c) => c.uuid !== component.uuid
        );
      }
    },

    removeQuestion(question) {
      this.survey.survey_components.forEach((comp) => {
        comp.survey_questions = comp.survey_questions.filter(
          (q) => q.uuid !== question.uuid
        );
      });
      this.editedQuestion = null;
    },

    onQuestionDrag(type) {
      return new QuestionModel({
        name: type.primary ? null : type.name,
        survey_question_type: type,
      });
    },
  },

  async mounted() {
    let resp = await ApiService.get("/opt/surveys");
    this.questionTypes = resp.data;
    this.id = this.$route.params.id;

    resp = await ApiService.get(`/surveys/${this.id}`);

    this.survey = resp.data;

    //sorting components and questions by uuid list
    this.survey.survey_components = orderByUUID(
      this.survey.survey_components,
      this.survey.componentOrder
    );
    this.survey.survey_components.forEach(
      (component) =>
        (component.survey_questions = orderByUUID(
          component.survey_questions,
          component.questionOrder
        ))
    );

    if (!this.survey.survey_components.length) this.addComponent();

    this.editedComponent = this.survey.survey_components[0];

    this.editedQuestion = _.get(this.editedComponent, "survey_questions[0]");
  },
};
</script>

<style>
.component-question-selected,
.component-question-selected:hover {
  border: 1.5px solid darkblue !important;
  background: aliceblue !important;
}

.component-selected {
  border: 1px solid darkblue !important;
  background: #f8feff;
}

.details {
  font-size: 12px;
}

.input-box {
  flex-shrink: 0;
  width: 250px;
}

.survey-box {
  flex-shrink: 0;
  /*width: 716px;*/
}

.component-title-item {
  font-size: 12px;
}

.component-question-box {
  padding: 3px;
  font-size: 11px;
  margin: 2px;
  background-color: #fff;
  border: 1px dashed #aaa;
  width: 110px;
  height: 45px;
}

.component-question-box:hover {
  cursor: pointer;
  border: 1px solid darkblue;
  background: aliceblue;
}

.component-question-box label {
  cursor: pointer;
}

.component-question-ko {
  /*background: #fff6f6;*/
  border-color: red;
}

.component-question-ok {
  background: white;
  border: 1px solid green;
}

.component-edit-box {
  padding: 3px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 1px dashed;
  min-height: 106px;
  width: 693px;
}

.detail-box {
  font-size: 12px;
  flex-shrink: 0;
  background: #f8f8f8;
  width: 370px;
}

.divider {
  margin: 5px 0px 5px 0px;
  border-top: 3px solid #545f89;
}

.main-container {
  width: 1400px;
  overflow-x: scroll;
}

.legend-title {
  font-size: 13px;
  font-weight: normal;
}
</style>
