<template>

  <fieldset class="m-0 p-1">
    <legend><b-badge class="legend-title">Condiciones</b-badge></legend>

    <div class="d-flex flex-column">
      <label class="m-0">componente:</label>
      <div class="d-flex">
        <select style="width: 320px" v-model="selectedComponent">
          <option :key="`cb_component_${key}`" v-for="(comp, key) in components" v-bind:value="comp">{{ comp.name || comp.uuid }}</option>
        </select>
        <b-button @click="selectedComponent=null" size="sm" class="btn-small mr-1 no-radius" variant="outline-secondary">
          <b-icon icon="backspace"></b-icon>
        </b-button>
      </div>
    </div>

    <div class="d-flex flex-column">
      <label class="m-0">pregunta:</label>
      <div class="d-flex">
        <select style="width: 320px" v-model="selectedQuestion">
          <option :key="`cb_question_${key}`" v-for="(quest, key) in xQuestions" v-bind:value="quest">{{ quest.name || quest.uuid }}</option>
        </select>
        <b-button @click="selectedQuestion=null" size="sm" class="btn-small mr-1 no-radius" variant="outline-secondary">
          <b-icon icon="backspace"></b-icon>
        </b-button>
      </div>
    </div>


    <div  class="d-flex" v-if="selectedQuestion">

      <div class="d-flex flex-column" >
        <label class="m-0">operador:</label>
        <div class="d-flex">
          <select style="width: 120px" class="mr-1" v-model="selectedOperator">
            <option :key="`cb_operator_${key}`" v-for="(opt, key) in operatorOpt[xType]" v-bind:value="opt">{{ key }}</option>
          </select>
        </div>
      </div>

      <div class="d-flex flex-column">
        <label class="m-0">valor:</label>

        <div class="d-flex">

          <div v-if="[QUESTION_TYPES.BOOL].includes(selectedQuestion.survey_question_type.type)">

            <select style="width: 190px" v-model="selectedValue">
              <option value="true">true</option>
              <option value="false">false</option>
            </select>

          </div>

          <b-button @click="add" size="sm" class="btn-small mr-1 no-radius" variant="outline-secondary">
            <b-icon icon="plus-circle"></b-icon>
          </b-button>

        </div>

      </div>


    </div>

    <div class="border font-xs" style="color: blueviolet" :key="`conditions_values_${key}`" v-for="(cond,key) in this.question.conditionals">

      <div class="d-flex">

        <input type="button" class="button-xs mr-1" value="X" @click="remove(cond)">

        {{formatCondStr(cond)}}


      </div>
    </div>


  </fieldset>


</template>

<script>

  import { QUESTION_TYPES } from '../models';
  import { ellipsis, logError } from '@/utils'
  import _ from 'lodash'

  const OP_STRING = {
    'igual': 'eq',
    'diferente': 'ne'
  }

  const OP_BOOL = {
    'igual': 'eq',
  }


  const OP_NUMBER = {
    '=': 'eq',
    '<': 'lt',
    '<=': 'le',
    '>': 'gt',
    '>=': 'ge',
  }

  export default {
    props: ["question","components"],
    computed: {

      xQuestions() {
        return this.selectedComponent ?
                this.selectedComponent.survey_questions.filter(q=>q.isCompleted() && q.uuid !== this.question.uuid)
                : null
      },

      xType() {
        return this.selectedQuestion ? this.selectedQuestion.survey_question_type.type : null
      }

    },
    data() {

      return {
        selectedComponent: null,
        selectedQuestion: null,
        selectedOperator: null,
        selectedValue: null,

        QUESTION_TYPES,

        operatorOpt: {
          [QUESTION_TYPES.DATE]: OP_NUMBER,

          [QUESTION_TYPES.BOOL]: OP_BOOL,

          [QUESTION_TYPES.STRING]: OP_STRING,

          [QUESTION_TYPES.LONGTEXT]: OP_STRING,

          [QUESTION_TYPES.RADIO]: OP_STRING,

          [QUESTION_TYPES.CHECKBOX]: OP_STRING,

          [QUESTION_TYPES.SELECT]: OP_STRING,

          [QUESTION_TYPES.MONEY]: OP_NUMBER,

          [QUESTION_TYPES.DECIMAL]: OP_NUMBER,

          [QUESTION_TYPES.NUMBER]: OP_NUMBER,

        }
      }
    },

    methods: {
      formatCondStr(cond) {
        return `[${ellipsis(cond.component,18)}][${ellipsis(cond.question,18)}] ${cond.op}: ${cond.val}`

      },

      ellipsis,

      add() {
        if(this.selectedOperator === null || this.selectedValue === null) {
          logError('valores nulos no aceptados')
          return
        }
        //checking non repeated values

        const toAdd = {
          component: this.selectedComponent.uuid,
          question: this.selectedQuestion.uuid,
          op: this.selectedOperator,
          val: this.selectedValue
        }

        if(!this.question.conditionals.some(el=>_.isEqual(el,toAdd))){ //checking duplicates
          this.question.conditionals.push(toAdd)
        }

      },
      remove(cond) {
        this.question.conditionals = this.question.conditionals.filter(el=>!_.isEqual(el,cond))
      }


    },
  }
</script>

<style>
</style>
