<template>

  <fieldset class="m-0 p-1" v-if="question && question.options">
    <legend><b-badge class="legend-title">Opciones Pregunta</b-badge></legend>

    <div class="d-flex">
      <label class="mr-1">Tipo:</label>
      {{question.survey_question_type.type}}
    </div>

    <div v-if="[QUESTION_TYPES.SELECT].includes(question.survey_question_type.type)">

      <label class="mr-1">Multiples:</label>

      <input
              class=""
              type="checkbox"
              v-model="question.options.multi"
      />

    </div>

    <div v-if="[QUESTION_TYPES.BOOL].includes(question.survey_question_type.type)">

      <label class="mr-1">Default:</label>

      <input
              type="checkbox"
              v-model="question.options.default"
      />

      {{question.options.default}}

    </div>

    <div v-if="[QUESTION_TYPES.CHECKBOX, QUESTION_TYPES.RADIO, QUESTION_TYPES.SELECT].includes(question.survey_question_type.type)">

      <div class="d-flex">

        <label class="mr-2">Añadir Opcion:</label>

        <input class="input-small" type="text" v-model="optVal"/>

        <b-button @click="addOpt(optVal)" size="sm" class="btn-small mr-1 no-radius" variant="outline-secondary">
          <b-icon icon="plus-circle"></b-icon>
        </b-button>

      </div>

      <div class="d-flex flex-column">

        <div class="border font-xs" style="color: blueviolet" :key="`question_opt_values_${key}`" v-for="(val,key) in question.options.values">

          <div class="d-flex justify-content-between">

            {{ellipsis(val,70)}}

            <input type="button" class="button-xs " value="X" @click="removeOpt(val)">

          </div>
        </div>
      </div>


    </div>






  </fieldset>


</template>

<script>

  import { QUESTION_TYPES } from '../models';
  import { ellipsis, logError } from '@/utils'
  import _ from 'lodash'

  export default {
    props: ["value","question"],
    computed: {
      xType() {
        return this.selectedQuestion ? this.selectedQuestion.survey_question_type.type : null
      }
    },

    methods: {
      ellipsis,

      removeOpt(val) {
        this.question.options.values = this.question.options.values.filter(el => el !== val)
      },

      addOpt(val) {
        if(val === null) {
          logError('valores nulos no aceptados')
          return
        }

        if(!this.question.options.values.includes(val)) {
          this.question.options.values.push(val)
        }
      }
    },
    data() {
        return {
          QUESTION_TYPES: QUESTION_TYPES,
          optVal: null,

        }
    },

    mounted() {
      if(_.isEmpty(this.question.options)) { //initialize

        if([QUESTION_TYPES.CHECKBOX, QUESTION_TYPES.RADIO].includes(this.question.survey_question_type.type)) {
          this.question.options = {
            values: []
          }
        }

        if([QUESTION_TYPES.SELECT].includes(this.question.survey_question_type.type)) {
          this.question.options = {
            values: [],
            multi: false
          }
        }

        if([QUESTION_TYPES.BOOL].includes(this.question.survey_question_type.type)) {
          this.question.options = {
            default: false
          }
        }

      }


    }

  }
</script>

<style>

</style>
