<template>


    <div class="d-flex flex-column">

      <div class="d-flex">
        <label class="mr-1">id:</label>
        <b-badge v-if="!question.id" variant="danger" class="mt-1 mx-1" style="height: 16px;">N/A</b-badge>
        {{question.id}}
      </div>

      <div class="d-flex">
        <label class="mr-1">uuid:</label>{{question.uuid}}
      </div>

      <div class="d-flex">
        <label class="mr-1 mb-2">name:</label>
        <input class="input-small" type="text" v-model="question.name"/>
      </div>

      <div class="d-flex">

        <label class="mr-1 mb-2">ocultar nombre:</label>

        <input
                class="mr-3"
                type="checkbox"
                v-model="question.hideLabel"
        />

      </div>


      <div class="d-flex">
        <label class="mr-1 mb-1">desactivar?: </label>
        <input
                class="mr-3"
                style="margin-top: 3px"
                type="checkbox"
                v-model="question.hide"
        />
      </div>



      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column datepicker">
          <label class="m-0">activo desde:</label>
          <b-form-datepicker
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm" locale="es"
                  v-model="question.activeDateFrom"
                  class="mb-2">

          </b-form-datepicker>
        </div>

        <div class="d-flex flex-column datepicker">
          <label class="m-0">hasta:</label>
          <b-form-datepicker
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm" locale="es"
                  v-model="question.activeDateTo"
                  class="mb-2">

          </b-form-datepicker>
        </div>
      </div>


      <div class="d-flex">
        <label class="mr-1 mb-2">se repite?: </label>
        <input
                class="mr-3"
                style="margin-top: 3px"
                type="checkbox"
                v-model="question.repeats"
        />
        <template v-if="question.repeats">
          <label class="mr-1">cada: </label>
          <input
                  min="0"
                  class="input-xs mr-1"
                  style="width: 50px"
                  type="number"
                  v-model="question.repeatsUnit"
          />

          <select class="input-xs" v-model="question.repeatsValue">
            <option value="days">dias</option>
            <option value="weeks">semanas</option>
            <option value="months">meses</option>

          </select>


        </template>

      </div>

      <div class="d-flex mb-1">
        <label class="mr-1">es obligatoria?: </label>
        <input
                class=""
                style="margin-top: 3px"
                type="checkbox"
                v-model="question.isMandatory"
        />
      </div>

      <div class="d-flex">

        <label class="mr-1 mb-1">preset: </label>

        <select class="mr-1 input-xs" v-model="question.presetValue">
          <option :key="`preset_value_opt_${key}`" v-for="(value, key) in PRESET_VALUE_TYPES" v-bind:value="key">{{ value }}</option>
        </select>

      </div>

      <options-builder :key='"ob_"+question.uuid' v-model="question" :question="question"></options-builder>

      <conditional-builder :key='"cb_"+question.uuid' :components="components" class="mt-1" :question="question"></conditional-builder>

      <div class="d-flex justify-content-end">
        <b-button @click="remove" size="sm" style="width: 60px" class="mt-2 btn-small" variant="outline-danger">eliminar</b-button>
      </div>


    </div>



</template>

<script>

  import conditionalBuilder from './conditionalBuilder'
  import optionsBuilder from './optionsBuilder'
  import {QUESTION_TYPES} from "../models";


  const PRESET_VALUE_TYPES = {
    new: 'sin preset', // does not preset
    once: 'ejecución unica', //pregunta de ejecucion unica
    previous: 'valor últ. sesión', //presets with las session value
    info: 'últ. sesión solo info', // display previous value as info
  }

  export default {
    props: ["question","components"],

    components: {
      conditionalBuilder,
      optionsBuilder
    },

    data() {
      return {
        PRESET_VALUE_TYPES,
      }
    },

    computed: {},
    
    methods: {
      remove() {
        this.$emit("remove");
      },
      nameWithLang ({ name, language }) {
        return `${name} — [${language}]`
      }
    },



  };
</script>

<style>


</style>
